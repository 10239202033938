<template>
  <page-skeleton theme="light">
    <section-half-image
        arrow
        scroll-to="mechanical-handling-arrow"
        hero
        small-photo
        img-background="gradient"
        :alt="$t('solutions.mechanical.images.alt1')"
        image-path="https://ik.imagekit.io/isotronic/solutions/mechanical/mechanical-wireframe_rAr8Dxyr3Z.png?updatedAt=1635404065479"
    >
      <template #header>
        {{ $t('solutions.mechanical.hero.header') }}
      </template>
      <template #content>
        <div class="mb-3 i-font-text i-secondary">
          {{ $t('solutions.mechanical.hero.text') }}
        </div>
      </template>
    </section-half-image>
    <features id="mechanical-handling-arrow"/>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/solutions/mechanical/production_line_YyT8McClX6.JPEG?updatedAt=1635404130527"
        :alt="$t('solutions.mechanical.images.alt2')"
    />
    <section-half-image
        round-radius
        small-photo
        photo-right-align
        :alt="$t('solutions.mechanical.section2.alt')"
        image-path="https://ik.imagekit.io/isotronic/solutions/mechanical/measurement_station_WP834W4lMPD.jpg?updatedAt=1635404179529"
    >
      <template #header>
        {{ $t('solutions.mechanical.section2.header') }}
      </template>
      <template #content>
        <div class="mb-3 i-font-text i-secondary">
          {{ $t('solutions.mechanical.section2.text') }}
        </div>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/solutions/mechanical/mechanical_handling_Sgsb21mFd3.jpg?updatedAt=1635404214438"
        :alt="$t('solutions.mechanical.images.alt3')"
    />
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";
import Features from "@/components/solutions/mechanicalHandling/HandlingFeatures";

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage,
    Features
  },
  metaInfo() {
    return {
      title: this.$t('solutions.mechanical.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('solutions.mechanical.meta.description')}
      ]
    }
  },
}
</script>
