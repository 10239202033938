<template>
  <b-row class="justify-content-center i-border i-outer-padding central-section i-vertical-no-photo">
    <b-col cols="12" sm="9" md="6"
           class="text-uppercase text-left text-sm-center">
      <i18n path="solutions.mechanical.section1.header" tag="h2">
        <template v-slot:headerGrey>
          <div class="i-secondary header-grey">
            {{ $t('solutions.mechanical.section1.headerGrey') }}
          </div>
        </template>
      </i18n>
    </b-col>
    <div class="w-100" />
    <b-col cols="12" class="pt-3 pb-4 pb-md-5 text-left text-sm-center">
      <div class="i-secondary i-text">
        {{ $t('solutions.mechanical.section1.subheader') }}
      </div>
    </b-col>
    <b-col v-for="(feature, index) in features"
           :key="'feature'+index"
           cols="12" md="6" lg="5">
      <feature-tile
          :src="feature.src"
          :alt="feature.alt"
      >
        <template #header>
          {{feature.title}}
        </template>
        <template #subheader>
          {{feature.text}}
        </template>
      </feature-tile>
    </b-col>
  </b-row>
</template>

<script>
import FeatureTile from "@/components/utility/FeatureTile"

export default {
  components: {
    FeatureTile
  },
  //todo : go over these points and edit them
  data() {
    return {
      features: [
        {
          title: this.$t('solutions.mechanical.section1.features.feature1.title'),
          text: this.$t('solutions.mechanical.section1.features.feature1.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature1.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/High_Accuracy_And_Speed_copy_1_n3-cV2hxzd.svg?updatedAt=1631204838696',
        },
        {
          title: this.$t('solutions.mechanical.section1.features.feature2.title'),
          text: this.$t('solutions.mechanical.section1.features.feature2.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature2.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/Cameras_1_LEtvku5h90L.svg?updatedAt=1631204838654',
        },
        {
          title: this.$t('solutions.mechanical.section1.features.feature3.title'),
          text: this.$t('solutions.mechanical.section1.features.feature3.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature3.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/icons8-handle-with-care_1_YMYTuCLvZDn.svg?updatedAt=1631204839312',
        },
        {
          title: this.$t('solutions.mechanical.section1.features.feature4.title'),
          text: this.$t('solutions.mechanical.section1.features.feature4.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature4.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/Customized_Solutions_copy_1_HC4zItRSc.svg?updatedAt=1631204838752',
        },
        {
          title: this.$t('solutions.mechanical.section1.features.feature5.title'),
          text: this.$t('solutions.mechanical.section1.features.feature5.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature5.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/insights_Production_Insight_copy_1_Sa0HZSgTX-z.svg?updatedAt=1631204839989',
        },
        {
          title: this.$t('solutions.mechanical.section1.features.feature6.title'),
          text: this.$t('solutions.mechanical.section1.features.feature6.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature6.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/icons8-accuracy_1_23-UtwpFg8.svg?updatedAt=1631204838937',
        },
        {
          title: this.$t('solutions.mechanical.section1.features.feature7.title'),
          text: this.$t('solutions.mechanical.section1.features.feature7.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature7.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/icons8-tune_1_w2VNJsTbm.svg?updatedAt=1631204840078',
        },
        {
          title: this.$t('solutions.mechanical.section1.features.feature8.title'),
          text: this.$t('solutions.mechanical.section1.features.feature8.text'),
          alt: this.$t('solutions.mechanical.section1.features.feature8.alt'),
          src: 'https://ik.imagekit.io/isotronic/solutions/mechanical/features/icons8-diameter_1_jOR3etswcQxF.svg?updatedAt=1631204839164',
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.header-grey
  text-transform: none!important

.central-section
  background: rgba(100, 101, 104, 0.05)
</style>
